<template>
  <div class="search-wrapper ml-2">
    <div class="form-group with-icon-left search-form-group" v-bind:class="{ 'has-value': displayValue }">
      <div class="input-group">
        <input class="input" @input="debounceSearchInput" v-model="displayValue" placeholder="Search" />
        <i class="fa fa-search icon-left input-icon search-icon"></i>
        <i class="fa fa-times icon-right input-icon" @click="resetValue()"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce'

  export default {
    name: 'search-input',
    props: {
      value: {
        default: '',
        required: true
      },
    },
    data () {
      return {
        displayValue: '',
      }
    },
    mounted() {
      this.displayValue = this.value;
    },
    watch: {
      value: {
        handler (value) {
          this.displayValue = value || ''
        },
        immediate: true,
      }
    },
    computed: {
    },
    methods: {
      debounceSearchInput: debounce(function(e) {
        this.$emit('input', e.target.value)
      }, 500),

      resetValue() {
        this.displayValue = '';
        this.$emit('input', this.displayValue)
      }
    }
  }
</script>

<style scoped lang="scss">
  .search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    .with-icon-left {
      flex: auto;
      margin-bottom: 0;
      min-height: auto;
      input {
        border: 1px solid #dee2e6;
        min-width: 250px;
        background-color: #fff;
        padding: 6px 0;
        padding-left: 32px;
      }
      .input-icon {
        cursor: pointer;
        top: 8px;
        left: 9px;
        color: $rb-blue;
      }
      .icon-right {
        display: none;
        left: auto !important;
        right: 9px;
      }
      &.has-value {
        input {
          padding-right: 32px;
        }
        .icon-right {
          display: block;
        }
      }
    }
  }
</style>